body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 2rem;
}

.App-link {
  color: #61dafb;
}

// h3.online-count {
//   font-weight: bold;
// }

ul.user-list {
  li.active { 
    span.handle {
      font-weight: bold;
    }
  }
  li.inactive {
    text-decoration-line: line-through;
  }
}
